<template>
    <div class="add_order">
        <v-card>
            <v-tabs
                v-model="tab"
                background-color="#F5F7FA"
                centered
                dark
                icons-and-text
            >
            <v-tabs-slider style="width:100%"></v-tabs-slider>
                <v-tab href="#tab-1">
                    Create Sales Order
                <v-icon>mdi-application</v-icon>
                </v-tab>
                <v-tab href="#tab-2">
                    Estimation
                    <v-icon> mdi-ballot</v-icon>
                    </v-tab>

                    <v-tab href="#tab-3">
                    Quotation
                    <v-icon>mdi-ballot-recount</v-icon>
                    </v-tab>
                
                </v-tabs>
                <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                <v-card flat>
                    <v-card-text><createOrder></createOrder></v-card-text>
                </v-card>
                </v-tab-item>
                <v-tab-item value="tab-2">
                <v-card flat>
                    <v-card-text><createEstimation></createEstimation></v-card-text>
                </v-card>
                </v-tab-item>
                <v-tab-item value="tab-3">
                <v-card flat>
                    <v-card-text><createQuotation></createQuotation></v-card-text>
                </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import createOrder from "@/components/OrderCreate/createSalesOrder.vue";
import createEstimation from "@/components/OrderCreate/createEstimation.vue";
import createQuotation from "@/components/OrderCreate/createQuotation.vue";
export default {
    name: 'orders',
    data () {
      return {      
        tab: null,
        
      };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Dashboard", route: "/dashboard"},
            {title: "Order Management", route: "/order/list"},
            {title: "Create Order"},
    ]);
    },
    components:{
        createOrder,
        createEstimation,
        createQuotation
    }
}
</script>
<style>

.add_order .el-tabs__item.is-active{
    color: #fff !important;
    background-color: #409EFF !important;
}
.add_order .el-tabs__item{
    font-size: smaller !important;
}
.tab_icon.is-active{
    color: #fff !important;
}
.v-tab.v-tab--active{
    color: #fff !important;
    background-color: #409EFF !important;
}
.v-tab{
    color: #000 !important;
    font-size: xx-small;
    border: 2px solid #E3E3E3;
}
.v-icon{
    color: #409EFF !important;
}
.v-tab--active .v-icon{
    color: inherit !important;
}
.v-tabs-slider{
    background-color: #fff !important;
}
</style>